import React, { useState, useEffect } from "react";
import Select from "react-select";

const ApplyNow = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    cdcNo: "",
    appliedRank: "",
    rankCD: "",
    BirthDate: "",
    mobileNo: "",
    emailId: "",
    indosNo: "",
    PassportNo: "",
    cvFile: null,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [Ranks, setRanks] = useState([]);
  const [token, setToken] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const authenticate = async () => {
    try {
      const response = await fetch(
        "http://125.63.83.102:1110/api/authenticate/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: "sisadmin",
            password: "dEv@$1$@2024##",
          }),
        }
      );

      if (!response.ok) throw new Error("Authentication failed");

      const data = await response.json();
      setToken(data.token);
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };
  const validate = () => {
    const newErrors = {};
  
    // Trim whitespaces from all form fields
    const trimmedFormData = { ...formData };
    for (let key in trimmedFormData) {
      if (typeof trimmedFormData[key] === "string") {
        trimmedFormData[key] = trimmedFormData[key].trim();
      }
    }
  
    // First Name Validation
    if (!trimmedFormData.firstName) newErrors.firstNameError = "Firstname is required";
  
    // Last Name Validation
    if (!trimmedFormData.lastName) newErrors.lastNameError = "Lastname is required";
  
    // Email Validation
    if (!trimmedFormData.emailId) newErrors.emailIdError = "Email ID is required";
  
    // Rank Validation
    if (!trimmedFormData.appliedRank) newErrors.appliedRank = "Rank is required";
  
    // Mobile Number Validation
    if (!trimmedFormData.mobileNo) newErrors.mobileError = "Mobile Number is required";
  
    // Date of Birth Validation (18 years or older)
    if (!trimmedFormData.BirthDate) {
      newErrors.BirthDate = "Date of Birth is required";
    } else {
      const today = new Date();
      const birthDate = new Date(trimmedFormData.BirthDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) newErrors.BirthDate = "Age must be 18 years or older";
    }
  
    const cdcNoRegex = /^[A-Za-z0-9]+$/;
    if (!trimmedFormData.cdcNo) {
      newErrors.cdcNo = "CDC No is required";
    } else if (!cdcNoRegex.test(trimmedFormData.cdcNo)) {
      newErrors.cdcNo = "CDC No must contain only alphanumeric characters";
    }
  
    const indosNoRegex = /^[A-Za-z0-9]+$/;
    if (!trimmedFormData.indosNo) {
      newErrors.indosNo = "INDOS No is required";
    } else if (!indosNoRegex.test(trimmedFormData.indosNo)) {
      newErrors.indosNo = "INDOS No must contain only alphanumeric characters";
    }
  
    const passportNoRegex = /^[A-Z]{1}[0-9]{7}$/;
    if (!trimmedFormData.PassportNo) {
      newErrors.PassportNo = "Passport Number is required";
    } else if (!passportNoRegex.test(trimmedFormData.PassportNo)) {
      newErrors.PassportNo = "Passport No must start with one letter followed by 7 digits";
    }
  
    
    if (!formData.cvFile) newErrors.cvFile = "Please upload the CV";
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleChange = (e) => {
    const { name, value, files } = e.target;
      if (name === "cvFile") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value.trim(),
      });
    }
    validateField(name, value.trim());
  };
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
        if (!value) {
          error = "Firstname is required";
        }
        break;
      case "lastName":
        if (!value) {
          error = "Lastname is required";
        }
        break;
      case "emailId":
        if (!value) {
          error = "Email ID is required";
        }
        break;
      case "appliedRank":
        if (!value) {
          error = "Rank is required";
        }
        break;
      case "mobileNo":
        if (!value) {
          error = "Mobile Number is required";
        }
        if (!/^\d{10}$/.test(value)) {
          error = "Mobile number must be exactly 10 digits.";
        }
        break;
      case "BirthDate":
        if (!value) {
          error = "Date of Birth is required";
        } else {
          const today = new Date();
          const birthDate = new Date(value);
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          if (age < 18) error = "Age must be 18 years or older";
        }
        break;
      case "cdcNo":
        if (!/^[A-Za-z0-9]+$/.test(value)) {
          error = "CDC No must contain only alphanumeric characters";
        }
        break;
      case "indosNo":
        if (!/^[A-Za-z0-9]+$/.test(value)) {
          error = "INDOS No must contain only alphanumeric characters";
        }
        break;
      case "PassportNo":
        if (!/^[A-Z]{1}[0-9]{7}$/.test(value)) {
          error = "Passport No must start with one letter followed by 7 digits";
        }
        break;
      default:
        break;
    }
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  
  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      appliedRank: selectedOption ? selectedOption.label : "",
      rankCD: selectedOption ? selectedOption.value : "Others",
    });
  };

  const getRanks = async () => {
    if (!token) return;

    try {
      const response = await fetch(
        "http://125.63.83.102:1110/api/home/get-all-ranks",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch ranks");

      const data = await response.json();

      const rankOptions = data.map((rank) => ({
        value: rank.rankCD,
        label: rank.rankName,
        deckEngine: rank.deckEngine,
      }));
      rankOptions.push({ value: "others", label: "Others", deckEngine: "" });

      setRanks(rankOptions);
    } catch (error) {
      console.error("Error fetching ranks:", error);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    getRanks();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    if (!validate()) {
      setIsSubmitting(false);
      return;
    }
  
    const trimmedFormData = { ...formData };
    for (let key in trimmedFormData) {
      if (typeof trimmedFormData[key] === "string") {
        trimmedFormData[key] = trimmedFormData[key].trim();
      }
    }
  
    const formDataToSend = new FormData();
    for (const key in trimmedFormData) {
      formDataToSend.append(key, trimmedFormData[key]);
    }
  
    try {
      const response = await fetch(
        "http://125.63.83.102:1110/api/seafarer/submit-seafarer-details",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDataToSend,
        }
      );
  
      if (!response.ok) throw new Error("Network response was not ok");
  
      setFormData({
        firstName: "",
        middleName: "",
        lastName: "",
        cdcNo: "",
        appliedRank: "",
        rankCD: "",
        BirthDate: "",
        mobileNo: "",
        emailId: "",
        indosNo: "",
        PassportNo: "",
        cvFile: null,
      });
      setErrors({});
      
      console.log("Form submitted successfully");
      setIsModalOpen(true);
  
      
    } catch (error) {
      console.log(formData,"ss")
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-200">
      <div className="w-full max-w-4xl p-8 bg-white shadow-lg rounded-lg my-10">
        <div className="text-center text-sm mb-6">
          Please fill in the below form and submit.
        </div>
        <div className="flex flex-wrap items-center justify-center -mx-2">
          <div className="w-full md:w-1/2 px-2">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  First Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.firstName && <span className="error-text text-red-500">{errors.firstName}</span>}
              </div>
              
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Middle Name</label>
                <input
                  type="text"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Last Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  CDC No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="cdcNo"
                  value={formData.cdcNo}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.cdcNo && <p className="text-red-500 text-xs mt-1">{errors.cdcNo}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Rank Applied For <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={Ranks}
                  value={Ranks.find((option) => option.value === formData.appliedRank)}
                  onChange={handleSelectChange}
                  className="w-full"
                  isSearchable
                  placeholder="Select Rank"
                />
                {errors.appliedRank && <p className="text-red-500 text-xs mt-1">{errors.appliedRank}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Date of Birth <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="BirthDate"
                  value={formData.BirthDate}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.BirthDate && <p className="text-red-500 text-xs mt-1">{errors.BirthDate}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleChange}
                  onBlur={(e) => validateField(e.target.name, e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.mobileNo && <p className="text-red-500 text-xs mt-1">{errors.mobileNo}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Email ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.emailId && <p className="text-red-500 text-xs mt-1">{errors.emailId}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">INDOS No</label>
                <input
                  type="text"
                  name="indosNo"
                  value={formData.indosNo}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.indosNo && <p className="text-red-500 text-xs mt-1">{errors.indosNo}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Passport No</label>
                <input
                  type="text"
                  name="PassportNo"
                  value={formData.PassportNo}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.PassportNo && <p className="text-red-500 text-xs mt-1">{errors.PassportNo}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Upload CV <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  name="cvFile"
                  accept=".pdf,.doc,.docx"
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.cvFile && <p className="text-red-500 text-xs mt-1">{errors.cvFile}</p>}
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className={`px-6 py-2 text-white font-semibold rounded-lg shadow-md ${
                    isSubmitting
                      ? "bg-gray-500 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-700 focus:bg-blue-700"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <SuccessModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default ApplyNow;


const SuccessModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Close after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4 text-[#296D9C]">Success!</h2>
        <p>Your form has been successfully submitted.</p>
      </div>
    </div>
  );
};